import { type SVGProps } from 'react';
import clsx from 'clsx';

import { type IconName } from '@/types/name';

import sprite from './sprite.svg?url';

import styles from './styles.module.scss';

export { IconName };

/**
 * Renders an SVG icon. The icon defaults to the size of the font. To make it
 * align vertically with neighboring text, you can pass the text as a child of
 * the icon, and it will be automatically aligned.
 * Alternatively, if you're not ok with the icon being to the left of the text,
 * you need to wrap the icon and text in a common parent and set the parent to
 * display "flex" (or "inline-flex") with "items-center" and a reasonable gap.
 */
function Icon({
  name,
  className,
  ...props
}: SVGProps<SVGSVGElement> & {
  name: IconName;
  className?: string;
}) {
  return (
    <svg {...props} className={clsx(styles.default, className)}>
      <use href={`${sprite}#${name}`} />
    </svg>
  );
}

export default Icon;
