import { Format, PosterOption, QualityVariantType } from '@/types/products';

export const CUSTOMIZABLE_CARD_ID = 'cardsCustom';

export const CARD_GROUP_ID = 'card';

export const PREFERRED_PAPER_QUALITY_ORDER = [
  QualityVariantType.DoubleSidedStandard,
  QualityVariantType.FoldedStandard,
  QualityVariantType.DoubleSidedPremium,
  QualityVariantType.FoldedPremium,
];
export const PREFERRED_FORMAT_ORDER = [Format.DoubleSided, Format.Folded];

export const PREFERRED_POSTER_OPTIONS_ORDER = [
  PosterOption.Normal,
  PosterOption.Adhesive,
];
