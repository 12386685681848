'use client';

import { useEffect } from 'react';

import { sendLog } from '@/services/logs';

export default function ScreenEnterLogger({
  name,
  additionalData,
}: {
  name: string;
  additionalData?: Record<string, unknown>;
}) {
  useEffect(() => {
    if (name)
      sendLog('ui_generic_screenEnter', {
        screen: name,
        pathname: window?.location.pathname,
        ...additionalData,
      });
  }, [name, additionalData]);

  return null;
}
