import { UploaderImage, UploaderStatus } from '../../types/image';
import { UploaderJob } from '../../types/job';
import isStatusAfter from '../status/isStatusAfter';

const getImagesByStatus = <T extends UploaderStatus>({
  jobs,
  imageStatus,
}: {
  jobs: UploaderJob[];
  imageStatus: T;
}): UploaderImage<T>[] =>
  // flatMap is used to get all images in one array instead of an array of array [[image1, image2], [image3, image4]
  jobs.flatMap((job) =>
    // If job status is after added it means that all images are at least in preparing
    isStatusAfter(job.status, imageStatus)
      ? []
      : // filter images by status - alternative is to do a function getImageByStatus per status
        (job.images.filter(
          (image) => image.status === imageStatus,
        ) as unknown as UploaderImage<T>[]),
  );

export default getImagesByStatus;
