import { AddPaymentInfoGTMProps } from './UAecommerce/addPaymentInfo';
import { AddShippingInfoProps } from './UAecommerce/addShippingInfo';
import { AddToCartGTMProps } from './UAecommerce/addToCart';
import { BeginCheckoutGTMProps } from './UAecommerce/beginCheckout';
import { SendPurchaseGTMProps } from './UAecommerce/purchase';
import { RemoveFromCartGTMProps } from './UAecommerce/removeFromCart';
import { SelectItemListGTMProps } from './UAecommerce/selectItem';
import { ViewCartGTMProps } from './UAecommerce/viewCart';
import { ViewItemListGTMProps } from './UAecommerce/viewItemList';

type SendLogOptions = {
  now?: boolean;
  side?: 'client' | 'server';
  deviceId?: string;
};

type SendLogDebouncedParams = SendLogOptions & {
  debounceDelay?: number;
};

export type SendLog = (
  event: string,
  value?: Record<string, unknown>,
  options?: SendLogOptions,
) => void;
export type SendLogDebounced = (
  event: string,
  value?: Record<string, unknown>,
  params?: SendLogDebouncedParams,
) => void;

export type Log = {
  timestamp?: number;
  event: string;
  value: Record<string, unknown>;
};

export type GTMLog =
  | {
      event: GTMLogEvent.addPaymentInfo;
      value: AddPaymentInfoGTMProps;
    }
  | {
      event: GTMLogEvent.addShippingInfo;
      value: AddShippingInfoProps;
    }
  | {
      event: GTMLogEvent.addToCart;
      value: AddToCartGTMProps;
    }
  | {
      event: GTMLogEvent.beginCheckout;
      value: BeginCheckoutGTMProps;
    }
  | {
      event: GTMLogEvent.purchase;
      value: SendPurchaseGTMProps;
    }
  | {
      event: GTMLogEvent.removeFromCart;
      value: RemoveFromCartGTMProps;
    }
  | {
      event: GTMLogEvent.selectItem;
      value: SelectItemListGTMProps;
    }
  | {
      event: GTMLogEvent.viewCart;
      value: ViewCartGTMProps;
    }
  | {
      event: GTMLogEvent.viewItemList;
      value: ViewItemListGTMProps;
    };

export enum GTMLogEvent {
  addPaymentInfo = 'addPaymentInfo',
  addShippingInfo = 'addShippingInfo',
  selectItem = 'selectItem',
  viewCart = 'viewCart',
  viewItemList = 'viewItemList',
  addToCart = 'addToCart',
  beginCheckout = 'beginCheckout',
  purchase = 'purchase',
  removeFromCart = 'removeFromCart',
}
