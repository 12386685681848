import { OrderUser, PaymentPlatform } from '@/stores/Order/types';
import { Page } from '@/types/template';

import { Store } from './store';

export type ItemMetadata = Record<string, unknown>;

export enum OrderStatus {
  FAILED = 'failed',
  FAILED_PERMANENTLY = 'failed_permanently',
  PAID = 'paid',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  PERSISTED = 'persisted',
  PICKED_UP = 'picked_up',
  POSTED = 'posted',
  READY_FOR_PICKUP = 'ready_for_pickup',
  PAYMENT_CANCELED = 'payment_canceled',
  REFUND_PENDING = 'refund_pending',
  REFUNDED = 'refunded',
}

export interface PostOrderItem {
  productId: string;
  quantity: number;
  pages: Page[];
  templateId?: number;
  designId?: number;
  metadata?: ItemMetadata;
  previewUrls?: string[];
  unitPrice?: number;
}

export interface PostOrder {
  id: string;
  storeId: string;
  coupon?: string;
  items: PostOrderItem[];
  user: OrderUser;
  billing?: {
    paymentPlatform: PaymentPlatform;
    items: {
      total: number;
      discountedTotal?: number;
      prepaidAmount: number;
    };
    fee: {
      total: number;
      prepaidAmount: number;
    };
  };
}

export interface OrderPrice {
  currency: string;
  rawTotal: number;
  total: number;
  prepaidTotal?: number;
  remainingTotal: number;
  prepaidType: string;
  discountedTotal?: number;
}

export interface HistoryOrder {
  id: string;
  dateCreation: number;
  status: OrderStatus;
  itemCount: number;
  price: OrderPrice;
  previewImages: string[];
}

export interface OrderDetails {
  id: string;
  dateCreation: Date;
  store: Store;
  storeId: string;
  user: OrderUser;
  items: GetOrderItem[];
  status: OrderStatus;
  statuses: {
    status: OrderStatus;
    date: Date;
  }[];
  price: OrderPrice;
  estimatedPickupTime?: number;
  refundUrl?: string;
}

export interface GetOrderItem extends PostOrderItem {
  product: {
    id: string;
    labels: {
      name: string;
      shortName: string;
    };
  };
}

export interface ProductDetails {
  productId: string;
  product: {
    id: string;
    labels: {
      name: string;
      shortName?: string;
    };
  };
  quantity: number;
  unitPrice: number;
  previewUrls: string[];
  pages: Page[];
  templateId?: number;
  designId?: number;
  metadata?: ItemMetadata;
  version: number;
}

export interface PutOrderResponse {
  id: string;
  dateCreation: Date;
  storeId: string;
  store: Store;
  user: OrderUser;
  items: ProductDetails[];
  status: string;
  statues: Array<{
    status: string;
    date: Date;
  }>;
  price: OrderPrice;
  estimatedPickupTime: Date;
}

export interface CheckoutDataResponse {
  coupon: {
    code: string;
    description: string;
  };
  rawTotal: number;
  currency: string;
  total: number; // after quantity discount
  discountedTotal: number; // after quantity discount and coupon
  prepaidTotal: number; // to pay online (stripe)
  paymentInfo: {
    customer: string;
    ephemeralKey: string;
    paymentIntent: string;
    paymentIntentId: string;
    publishableKey: string;
  };
}
