import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import Button from '@/components/Button';

import Icon from '../Icon';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '../Modal';

import DevContent from './DevContent';

import styles from './styles.module.scss';

function Content() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className={styles.openButton}>
          <Button type="button" theme="transparent">
            <div className={styles.openButtonWrapper}>
              <Icon name="terminal" />
            </div>
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent size="fullscreen">
        <VisuallyHidden>
          <DialogTitle>Dev Menu</DialogTitle>
          <DialogDescription>Dev Menu Description</DialogDescription>
        </VisuallyHidden>

        <DevContent />
      </DialogContent>
    </Dialog>
  );
}

export default Content;
